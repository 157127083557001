<template>
    <section class="section">
        <base-config-card title="Table Setup" subtitle="Setup pre-determined database tables">
            <div class="table-container">
                <div class="py-2">
                    <div class="pl-2">

                        <ui5-label for="databaseTableSelect" class="filter-input dbt-select-label">
                            Selected Table:
                        </ui5-label>

                        <ui5-select id="databaseTable" class="input select" placeholder="Select Table"
                            @change="setSelectedTableName">
                            <ui5-option v-for="tablename in tableNames" :key="tablename" :value="tablename">
                                {{ tablename }}
                            </ui5-option>
                        </ui5-select>
                    </div>
                </div>

                <div class="py-2">
                    <div class="pl-2">
                        <div class="scrollable-table-container">
                            <ui5-table v-if="selectedTableName === 'Bank Holidays'" id="bank-holiday-table"
                                sticky-column-header>
                                <ui5-table-column slot="columns" class="date-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal">Date</ui5-label>
                                </ui5-table-column>
                                <ui5-table-column slot="columns" class="delete-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal"></ui5-label>
                                </ui5-table-column>

                                <ui5-table-row v-for="bankHoliday in bankHolidays" :key="bankHoliday.id">
                                    <ui5-table-cell>
                                        {{ bankHoliday.date }}
                                    </ui5-table-cell>
                                    <ui5-table-cell>
                                        <ui5-button design="Negative" class="delete-button table-delete-button"
                                            @click.stop="deleteBankHoliday(bankHoliday.id)">
                                            Delete
                                        </ui5-button>
                                    </ui5-table-cell>
                                </ui5-table-row>
                            </ui5-table>

                            <ui5-table v-if="selectedTableName === 'Time Types'" id="time-type-table"
                                sticky-column-header>
                                <ui5-table-column slot="columns" class="id-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal">Id</ui5-label>
                                </ui5-table-column>
                                <ui5-table-column slot="columns" class="name-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal">Name</ui5-label>
                                </ui5-table-column>
                                <ui5-table-column slot="columns" class="reimbursement-type-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal">Absence
                                        Type</ui5-label>
                                </ui5-table-column>
                                <ui5-table-column slot="columns" class="delete-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal"></ui5-label>
                                </ui5-table-column>

                                <ui5-table-row v-for="timeType in timeTypes" :key="timeType.timeTypeDTO.id">
                                    <ui5-table-cell>
                                        {{ timeType.timeTypeDTO.id }}
                                    </ui5-table-cell>
                                    <ui5-table-cell>
                                        {{ timeType.timeTypeDTO.name }}
                                    </ui5-table-cell>
                                    <ui5-table-cell>
                                        {{ timeType.timeTypeLinkedReimbursementType }}
                                    </ui5-table-cell>

                                    <ui5-table-cell>
                                        <ui5-button design="Negative" class="delete-button table-delete-button"
                                            @click.stop="deleteTimeType(timeType.timeTypeDTO.id)">
                                            Delete
                                        </ui5-button>
                                    </ui5-table-cell>
                                </ui5-table-row>
                            </ui5-table>

                            <ui5-table v-if="selectedTableName === 'Value Mapping'" id="value-mapping-table"
                                sticky-column-header>
                                <ui5-table-column slot="columns" class="source-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal">Source</ui5-label>
                                </ui5-table-column>
                                <ui5-table-column slot="columns" class="target-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal">Target</ui5-label>
                                </ui5-table-column>
                                <ui5-table-column slot="columns" class="type-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal">Type</ui5-label>
                                </ui5-table-column>
                                <ui5-table-column slot="columns" class="delete-column">
                                    <ui5-label class="ui5-table-column-label" wrapping-type="Normal"></ui5-label>
                                </ui5-table-column>

                                <ui5-table-row v-for="valueMapping in valueMappings" :key="valueMapping.id">
                                    <ui5-table-cell>
                                        {{ valueMapping.source }}
                                    </ui5-table-cell>
                                    <ui5-table-cell>
                                        {{ valueMapping.target }}
                                    </ui5-table-cell>
                                    <ui5-table-cell>
                                        {{ valueMapping.type }}
                                    </ui5-table-cell>
                                    <ui5-table-cell>
                                        <ui5-button design="Negative" class="delete-button table-delete-button"
                                            @click.stop="deleteValueMapping(valueMapping.id)">
                                            Delete
                                        </ui5-button>
                                    </ui5-table-cell>
                                </ui5-table-row>
                            </ui5-table>
                        </div>
                    </div>
                </div>
                <div v-if="selectedTableName === 'Bank Holidays'" class="button-container">
                    <ui5-button class="add-bank-holiday-modal-button" @click.stop="openBankHolidayModal()">
                        Add Bank Holiday
                    </ui5-button>
                </div>

                <div v-if="selectedTableName === 'Time Types'" class="button-container">
                    <ui5-button class="add-time-type-modal-button" @click.stop="openTimeTypeModal()">
                        Add Time Type
                    </ui5-button>
                </div>

                <div v-if="selectedTableName === 'Value Mapping'" class="button-container">
                    <ui5-button class="add-value-mapping-modal-button" @click.stop="openValueMappingModal()">
                        Add Value Mapping
                    </ui5-button>
                </div>

                <BaseModal value="true" scrollable no-close footer-align="none" header-text="Bank Holiday"
                    v-model="bankHolidayModal" class="bank-holiday-modal">

                    <ui5-label for="bankHolidayDate" class="filter-input label">
                        Date:
                    </ui5-label>
                    <input ref="bankHolidayDateInput" id="bankHolidayDateInput" type="date"
                        class="filter-input datepicker" format-pattern="yyyy-MM-dd" style="width: 100%;"
                        v-model="bankHolidayDate" />

                    <div class="modal-buttons">
                        <ui5-button design="Emphasized" @click="createBankHoliday()">Add Bank Holiday</ui5-button>
                        <ui5-button design="Negative" @click="closeBankHolidayModal()">Cancel</ui5-button>
                    </div>
                </BaseModal>

                <BaseModal value="true" scrollable no-close footer-align="none" header-text="Time Type"
                    v-model="timeTypeModal" class="time-type-modal">

                    <ui5-label for="timeTypeAbsenceType" class="filter-input label">Absence Type: </ui5-label>
                    <Span>{{ timeTypeAbsenceType }}</Span>
                    <ui5-select id="absenceTypesSelect" ref="absenceTypesSelect" class="modal-input modal-select"
                        @change="setSelectedAbsenceType">
                        <ui5-option v-for="reimbursementType in reimbursementTypes" :key="reimbursementType"
                            :value="reimbursementType">{{ reimbursementType }}</ui5-option>
                    </ui5-select>
                    <ui5-label for="timeTypeId" class="filter-input label">Id: </ui5-label>
                    <ui5-input ref="timeTypeIdInput" id="timeTypeIdInput" placeholder="Time Type Id" :value="timeTypeId"
                        @input="setTimeTypeId" class="modal-input" />

                    <ui5-label for="timeTypeName" class="filter-input label">Name: </ui5-label>
                    <ui5-input ref="timeTypeNameInput" id="timeTypeNameInput" placeholder="Time Type Name"
                        :value="timeTypeName" @input="setTimeTypeName" class="modal-input" />

                    <div class="modal-buttons">
                        <ui5-button design="Emphasized" @click="createTimeType">Add Time Type</ui5-button>
                        <ui5-button @click="closeTimeTypeModal">Cancel</ui5-button>
                    </div>
                </BaseModal>

                <BaseModal value="true" scrollable no-close footer-align="none" header-text="Value Mapping"
                    v-model="valueMappingModal" class="value-mapping-modal">
                    <ui5-label for="valueMappingSource" class="filter-input label">
                        Source:
                    </ui5-label>
                    <ui5-input ref="valueMappingSourceInput" id="valueMappingSourceInput"
                        placeholder="Value Mapping Source" class="modal-input" :value="valueMappingSource"
                        @input="setValueMappingSource" />

                    <ui5-label for="valueMappingTarget" class="filter-input label">
                        Target:
                    </ui5-label>
                    <ui5-input ref="valueMappingTargetInput" id="valueMappingTargetInput"
                        placeholder="Value Mapping Target" class="modal-input" :value="valueMappingTarget"
                        @input="setValueMappingTarget" />
                    <ui5-label for="valueMappingType" class="filter-input label">
                        Type:
                    </ui5-label>
                    <ui5-select id="valueMappingTypesSelect" ref="valueMappingTypesSelect"
                        class="modal-input modal-select" @change="setValueMappingType">
                        <ui5-option v-for="valueMappingType in valueMappingTypes" :key="valueMappingType"
                            :value="valueMappingType">{{ valueMappingType }}</ui5-option>
                    </ui5-select>

                    <div class="modal-buttons">
                        <ui5-button design="Emphasized" @click="createValueMapping">Add Value Mapping</ui5-button>
                        <ui5-button @click="closeValueMappingModal">Cancel</ui5-button>
                    </div>
                </BaseModal>

                <BaseModal value="true" v-model="timeTypeLinkedToCaseModal" no-close
                    :header-text="timeTypeErrorModalTitle" footer-align="center" class="time-type-delete-error-modal">
                    <template>
                        <ui5-label class="label" wrapping-type="Normal">
                            {{ timeTypeLinkedToCaseModalMessage }}
                        </ui5-label>
                    </template>

                    <template #footer-buttons>
                        <div class="text-center">
                            <ui5-button id="closeErrorModalBtn" design="Emphasized"
                                @click="closeTimeTypeLinkedToCaseModal()">
                                Close
                            </ui5-button>
                        </div>
                    </template>
                </BaseModal>

                <BaseModal value="true" v-model="timeTypeAlreadyCreatedErrorModal" no-close
                    :header-text="timeTypeErrorModalTitle" footer-align="center" class="time-type-delete-error-modal">
                    <template>
                        <ui5-label class="label" wrapping-type="Normal">
                            {{ timeTypeAlreadyCreatedErrorModalMessage }}
                        </ui5-label>
                    </template>

                    <template #footer-buttons>
                        <div class="text-center">
                            <ui5-button id="closeErrorModalBtn" design="Emphasized"
                                @click="closetimeTypeAlreadyCreatedErrorModal()">
                                Close
                            </ui5-button>
                        </div>
                    </template>
                </BaseModal>
            </div>
        </base-config-card>
    </section>
</template>

<script>
import BaseConfigCard from './config/BaseConfigCard.vue';
import BaseModal from './common/BaseModal.vue';
import api from '../api/index.js'

export default {
    name: 'SetupPage',

    components: {
        BaseConfigCard,
        BaseModal
    },

    data() {
        return {
            busyLoading: false,
            selectedTableName: 'Bank Holidays',
            tableNames: [
                'Bank Holidays',
                'Time Types',
                'Value Mapping'
            ],
            bankHolidays: {},
            timeTypes: {},
            valueMappings: {},
            reimbursementTypes: {},
            valueMappingTypes: {},

            bankHolidayModal: false,
            bankHolidayDate: '',

            timeTypeLinkedToCaseModal: false,
            timeTypeAlreadyCreatedErrorModal: false,
            timeTypeErrorModalTitle: "Error",
            timeTypeLinkedToCaseModalMessage: "Unable to delete this time type as it is linked to a reimbursement case.",
            timeTypeAlreadyCreatedErrorModalMessage: "Unable to create Time Type as it already exists.",
            timeTypeModal: false,
            timeTypeAbsenceType: '',
            timeTypeId: '',
            timeTypeName: '',

            valueMappingModal: false,
            valueMappingSource: '',
            valueMappingTarget: '',
            valueMappingType: ''

        }
    },

    mounted() {
        this.getBankHolidaysList();
    },

    methods: {
        setTimeTypeId(event) {
            this.timeTypeId = event.target.value;
        },
        setTimeTypeName(event) {
            this.timeTypeName = event.target.value;
        },
        setValueMappingSource(event) {
            this.valueMappingSource = event.target.value;
        },
        setValueMappingTarget(event) {
            this.valueMappingTarget = event.target.value;
        },
        setValueMappingType(event) {
            this.valueMappingType = event.target.selectedOption?.value;
        },
        setSelectedTableName(event) {
            this.selectedTableName = event.target.selectedOption?.value;
        },
        setSelectedAbsenceType(event) {
            this.timeTypeAbsenceType = event.target.selectedOption?.value;
        },
        closeTimeTypeLinkedToCaseModal() {
            this.timeTypeLinkedToCaseModal = false;
        },
        closetimeTypeAlreadyCreatedErrorModal() {
            this.timeTypeAlreadyCreatedErrorModal = false;
        },

        getBankHolidaysList() {
            this.busyLoading = true;
            api
                .getAllBankHolidays()
                .then((response) => {
                    this.bankHolidays = response.data;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.busyLoading = false;
                });
        },

        deleteBankHoliday(bankHolidayId) {
            api
                .deleteBankHoliday(bankHolidayId)
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.getBankHolidaysList();
                })
        },

        createBankHoliday() {
            if (this.validBankHolidayInputs()) {
                const bankHolidayObj = {
                    date: this.bankHolidayDate
                }
                api
                    .createBankHoliday(bankHolidayObj)
                    .catch((error) => {
                        console.error(error)
                    })
                    .finally(() => {
                        this.getBankHolidaysList();
                    })
                this.closeBankHolidayModal();
            }
        },

        validBankHolidayInputs() {
            var valid = true;
            if (this.bankHolidayDate === '') {
                valid = false;
            }
            return valid;
        },

        openBankHolidayModal() {
            this.bankHolidayDate = '';
            this.bankHolidayModal = true;
        },

        closeBankHolidayModal() {
            this.bankHolidayModal = false;
        },

        getTimeTypesList() {
            this.busyLoading = true;
            api
                .getAllTimeTypes()
                .then((response) => {
                    this.timeTypes = response.data;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.busyLoading = false;
                })
        },

        createTimeType() {
            if (this.validTimeTypeInputs()) {
                const timeTypeObj = {
                    id: this.timeTypeId,
                    name: this.timeTypeName,
                    reimbursementType: this.timeTypeAbsenceType.replace(/\s*\(.*$/, ''),
                }
                api
                    .createTimeType(timeTypeObj)
                    .catch((error) => {
                        console.error(error)
                        this.timeTypeAlreadyCreatedErrorModal = true;
                    })
                    .finally(() => {
                        this.getTimeTypesList();
                        this.closeTimeTypeModal();
                    })
            }
        },

        initializeTimeTypeInputs() {
            this.timeTypeId = ''
            this.$refs.timeTypeIdInput.setAttribute('class', 'modal-input');
            this.timeTypeName = ''
            this.$refs.timeTypeNameInput.setAttribute('class', 'modal-input');
        },

        validTimeTypeInputs() {
            var valid = true;
            if (this.timeTypeId === '') {
                this.$refs.timeTypeIdInput.setAttribute('class', 'modal-input-error');
                valid = false;
            }
            if (this.timeTypeName === '') {
                this.$refs.timeTypeNameInput.setAttribute('class', 'modal-input-error');
                valid = false;
            }
            return valid;
        },

        openTimeTypeModal() {
            if (Object.keys(this.reimbursementTypes).length === 0) {
                this.getReimbursementTypes();
            }
            this.timeTypeModal = true;
        },

        getReimbursementTypes() {
            api.
                getReimbursementTypeSelectListOptions()
                .then((response) => {
                    this.reimbursementTypes = response.data;
                    this.timeTypeAbsenceType = this.reimbursementTypes[0];
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        getValueMappingTypes() {
            api.
                getValueMappingTypeSelectListOptions()
                .then((response) => {
                    this.valueMappingTypes = response.data;
                    this.valueMappingType = this.valueMappingTypes[0];
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        closeTimeTypeModal() {
            this.timeTypeModal = false;
            this.resetTimeTypeSelection();
            this.initializeTimeTypeInputs();
        },

        resetTimeTypeSelection() {
            this.$nextTick(() => {
                if (this.$refs.absenceTypesSelect) {
                    this.$refs.absenceTypesSelect._select(0);
                    this.timeTypeAbsenceType = this.reimbursementTypes[0];
                }
            });
        },

        deleteTimeType(timeTypeId) {
            api
                .deleteTimeType(timeTypeId)
                .catch((error) => {
                    console.error(error)
                    this.timeTypeLinkedToCaseModal = true;
                })
                .finally(() => {
                    this.getTimeTypesList();
                })
        },

        getValueMappingsList() {
            this.busyLoading = true;
            api
                .getAllValueMappings()
                .then((response) => {
                    this.valueMappings = response.data;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.busyLoading = false;
                })
        },

        deleteValueMapping(valueMappingId) {
            api
                .deleteValueMapping(valueMappingId)
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.getValueMappingsList();
                })
        },

        createValueMapping() {
            if (this.validValueMappingInputs()) {
                const valueMappingObj = {
                    source: this.valueMappingSource,
                    target: this.valueMappingTarget,
                    type: this.valueMappingType
                }
                api
                    .createValueMapping(valueMappingObj)
                    .catch((error) => {
                        console.error(error)
                    })
                    .finally(() => {
                        this.getValueMappingsList();
                        this.closeValueMappingModal();
                    })
            }
        },

        initializeValueMappingInputs() {
            this.valueMappingSource = ''
            this.$refs.valueMappingSourceInput.setAttribute('class', 'modal-input');
            this.valueMappingTarget = ''
            this.$refs.valueMappingTargetInput.setAttribute('class', 'modal-input');
            this.valueMappingType = this.valueMappingTypes[0];
        },

        validValueMappingInputs() {
            var valid = true;
            if (this.valueMappingSource === '') {
                this.$refs.valueMappingSourceInput.setAttribute('class', 'modal-input-error');
                valid = false;
            }
            if (this.valueMappingTarget === '') {
                this.$refs.valueMappingTargetInput.setAttribute('class', 'modal-input-error');
                valid = false;
            }
            if (this.valueMappingType === '') {
                this.$refs.valueMappingTypeInput.setAttribute('class', 'modal-input-error');
                valid = false;
            }
            return valid;
        },

        openValueMappingModal() {
            this.valueMappingModal = true;
            if (Object.keys(this.valueMappingTypes).length === 0) {
                this.getValueMappingTypes();
            }
        },

        closeValueMappingModal() {
            this.valueMappingModal = false;
            this.resetValueMappingSelection();
            this.initializeValueMappingInputs();
        },

        resetValueMappingSelection() {
            this.$nextTick(() => {
                if (this.$refs.valueMappingTypesSelect) {
                    this.$refs.valueMappingTypesSelect._select(0);
                    this.valueMappingType = this.valueMappingTypes[0];
                }
            });
        },

    },

    watch: {
        selectedTableName(newVal) {
            switch (newVal) {
                case 'Bank Holidays':
                    this.getBankHolidaysList();
                    return null;
                case 'Time Types':
                    this.getTimeTypesList();
                    return null;
                case 'Value Mapping':
                    this.getValueMappingsList();
                    return null;
                default:
                    console.error('Unknown table option selected');
                    return null;
            }
        }
    }
}
</script>

<style scoped>
.table-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}

.add-bank-holiday-modal-button {
    margin-right: 16px;
    margin-bottom: 16px;
}

.scrollable-table-container {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
}

.bank-holiday-modal {
    min-width: 600px;
    max-width: 500px;
    padding: 20px;
}

.modal-content label {
    border: none;
    background: none;
    padding: 0;
}

.modal-buttons {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.value-mapping-error-modal-button {
    display: center;
    justify-content: flex-end;
}

.ui5-label {
    border: none;
    background: none;
    padding: 0;
    margin-bottom: 5px;
    display: inline-block;
}

input.datepicker {
    padding: 5px;
    width: 50%;
    margin-top: 5px;
}

input.select {
    padding: 5px;
    width: 50%;
}

.time-type-modal {
    min-width: 600px;
    max-width: 500px;
    padding: 20px;
}

.value-mapping-modal {
    min-width: 600px;
    max-width: 500px;
    padding: 20px;
}

.time-type-delete-error-modal {
    min-width: 600px;
    max-width: 500px;
    padding: 20px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-input {
    width: 100%;
    margin-bottom: 15px;
    margin-left: -1px;
}

.modal-input-error {
    width: 100%;
    margin-bottom: 15px;
    margin-left: -1px;
    border: 1px solid red;
}

.modal-select {
    width: 100%;
    margin-bottom: 15px;
}

.dbt-select-label {
    margin-right: 20px;
}

.table-delete-button {
    margin-top: 5px;
}
</style>