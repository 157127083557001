<template>
  <section class="section">
    <ui5-busy-indicator
      id="employee-busy"
      :active="busyLoading"
      size="Medium"
      style="width: 100%"
    >
      <ui5-card class="medium">
        <ui5-card-header
          slot="header"
          :title-text="`${givenEmployee.name} (${givenEmployee.id})`"
          subtitle-text="Information for selected employee"
          interactive
          @click="toggleDetailedEmployee"
        >
          <!-- Header Icons -->
          <ui5-icon v-if="!detailedEmployee" slot="avatar" name="add" />
          <ui5-icon v-else slot="avatar" name="less" />
        </ui5-card-header>

        <b-row v-if="!detailedEmployee" style="width: 100%; margin: auto">
          <b-col
            v-for="(field, index) in employee.slice(0, 4)"
            :key="index"
            cols="3"
          >
            <component
              :is="field.fieldType"
              :value="employeeFieldData[field.name]"
              v-bind="field"
            />
          </b-col>
        </b-row>

        <b-row v-if="detailedEmployee" style="width: 100%; margin: auto">
          <b-col v-for="(field, index) in employee" :key="index" cols="3">
            <component
              :is="field.fieldType"
              :value="employeeFieldData[field.name]"
              v-bind="field"
            />
          </b-col>
        </b-row>
      </ui5-card>
    </ui5-busy-indicator>
  </section>
</template>

<script>
import api from '../api';

export default {
  name: 'BasicEmployee',
  props: {
    givenEmployee: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      employee: [],
      employeeFieldData: {},
      detailedEmployee: false,
      busyLoading: false,
    };
  },
  watch: {
    givenEmployee() {
      this.fetchEmployee();
    },
  },
  mounted() {
    this.fetchEmployee();
  },

  methods: {
    fetchEmployee() {
      if (!this.givenEmployee.id) {
        return;
      }

      this.busyLoading = true;
      api
        .getEmployeeDetails(this.givenEmployee.id)
        .then((response) => {
          this.employee = response.data.employee;
          this.employee.forEach((el) => {
            this.employeeFieldData[el.name] = el.value;
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.busyLoading = false;
        });
    },
    toggleDetailedEmployee() {
      this.detailedEmployee = !this.detailedEmployee;
    },
  },
};
</script>
