<template>
  <section class="section p-0 full-width">
    <ui5-table id="case-states-table" sticky-column-header>
      <ui5-table-column slot="columns" class="date">
        <ui5-label wrapping-type="Normal"> Date </ui5-label>
      </ui5-table-column>
      <ui5-table-column slot="columns" class="companyCode">
        <ui5-label wrapping-type="Normal"> Company code </ui5-label>
      </ui5-table-column>
      <ui5-table-column slot="columns" class="pNumber">
        <ui5-label wrapping-type="Normal"> P-Number </ui5-label>
      </ui5-table-column>
      <ui5-table-column slot="columns" class="caseStatus">
        <ui5-label wrapping-type="Normal"> Case status </ui5-label>
      </ui5-table-column>
      <ui5-table-column slot="columns" class="form">
        <ui5-label wrapping-type="Normal"> Form </ui5-label>
      </ui5-table-column>
      <ui5-table-column slot="columns" class="reimbursmeentDates">
        <ui5-label wrapping-type="Normal"> Reimbursement Start </ui5-label>
        <br />
        <ui5-label wrapping-type="Normal"> Reimbursement End </ui5-label>
      </ui5-table-column>
      <ui5-table-column slot="columns" class="estimatedAmount" style="width: 130px">
        <div class="d-flex flex-column">
          <ui5-label wrapping-type="Normal"> Estimated amount </ui5-label>
          <ui5-label wrapping-type="Normal"> (DKK) </ui5-label>
        </div>
      </ui5-table-column>
      <ui5-table-column slot="columns" class="receivedAmount" style="width: 130px">
        <div class="d-flex flex-column">
          <ui5-label wrapping-type="Normal"> Received amount </ui5-label>
          <ui5-label wrapping-type="Normal"> (DKK) </ui5-label>
        </div>
      </ui5-table-column>
      <ui5-table-column slot="columns" class="comment">
        <ui5-label wrapping-type="Normal"> Comment </ui5-label>
      </ui5-table-column>
      <ui5-table-column slot="columns" class="comment">
        <ui5-label wrapping-type="Normal"> Sent To Finance </ui5-label>
      </ui5-table-column>
      <ui5-table-row v-for="caseState in caseStates" :key="caseState.id">
        <ui5-table-cell>{{ caseState.date }}</ui5-table-cell>
        <ui5-table-cell>
          <div class="d-block pa-0">
            <strong>{{ caseState.companyCode }}</strong>
            <div v-if="caseState.costCenter">
              Cost Center: {{ caseState.costCenter }}
            </div>
          </div>
        </ui5-table-cell>
        <ui5-table-cell class="small-column">
          <div class="d-block pa-0">
            <strong>{{ caseState.pnumber }}</strong>
            <div v-if="caseState.employeeId">
              Emp ID: {{ caseState.employeeId }}
            </div>
          </div>
        </ui5-table-cell>
        <ui5-table-cell>{{ caseState.caseStatus }}</ui5-table-cell>
        <ui5-table-cell>
          <div class="d-block pa-0">
            <strong>{{ caseState.form }}</strong>
            <div>{{ caseState.caseType }}</div>
          </div>
        </ui5-table-cell>
        <ui5-table-cell>
          <div class="d-block pa-0">
            <div>{{ caseState.refundStartDate || '' }}</div>
            <div>
              {{ caseState.refundEndDate || '' }}
            </div>
          </div>
        </ui5-table-cell>
        <ui5-table-cell class="text-right">
          <div class="d-block pa-0">
            <div>{{ caseState.estimatedAmount || '-' }}</div>
          </div>
        </ui5-table-cell>
        <ui5-table-cell class="text-right">
          <div class="d-block pa-0">
            <div>{{ caseState.receivedAmount || '-' }}</div>
          </div>
        </ui5-table-cell>
        <ui5-table-cell>{{ caseState.comment }}</ui5-table-cell>
        <ui5-table-cell>{{ caseState.sentToFinance }}</ui5-table-cell>
      </ui5-table-row>
    </ui5-table>
    <div class="text-right" style="width: 100%; padding: 15px 15px 4px 15px">
      <ui5-button class="export-button" @click="generateExcelReport">
        Export to excel
      </ui5-button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CaseStatesReportList',
  props: {
    caseStates: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    generateExcelReport() {
      this.$emit('exportExcel');
    },
  },
};
</script>

<style scoped>
#case-states-table {
  max-height: calc(100vh - 220px);
  overflow-x: scroll;
  overflow-y: scroll;
}

#case-states-table ui5-table-cell > * {
  vertical-align: middle;
}

.small-column {
  width: 120px;
}
</style>
