const homeStore = {
  namespaced: true,
  state: {
    selectedFilter: '',
    selectedFilterValue: '',
  },
  mutations: {
    SET_SELECTED_FILTER(state, value) {
      state.selectedFilter = value;
    },
    SET_SELECTED_FILTER_VALUE(state, value) {
      state.selectedFilterValue = value;
    },
    RESET_SELECTED_FILTER(state) {
      state.selectedFilter = '';
    },
    RESET_SELECTED_FILTER_VALUE(state) {
      state.selectedFilterValue = '';
    },
  },
};

export default homeStore;
