import Config from '../components/Config.vue';
import FinanceAuditFilesList from '../components/FinanceAuditFilesList.vue';
import Home from '../views/HomePage.vue';
import LoginPage from '../views/LoginPage.vue';
import NotFound from '../components/NotFound.vue';
import Report from '../components/Report.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import api from '../api';
import store from '../store';
import TimeReport from '../components/TimeReport.vue';
import Setups from '../components/Setups.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/index.html',
    name: 'Index',
    component: LoginPage,
  },
  {
    path: '/config',
    name: 'Config',
    component: Config,
  },
  {
    path: '/reports',
    name: 'Reporting',
    component: Report,
  },
  {
    path: '/timeReport',
    name: 'Time Report',
    component: TimeReport,
  },
  {
    path: '/setups',
    name: 'Setups',
    component: Setups,
  },
  {
    path: '/finance',
    name: 'Finance',
    component: FinanceAuditFilesList,
  },
  { path: '*', name: 'NotFound', component: NotFound },
];

const router = new VueRouter({
  routes: routes,
});

export default router;

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Login' && store.getters.getLoggedIn) {
    const loggedIn = await api.isAuthenticated();
    if (loggedIn) {
      next({ name: 'Home' });
      return;
    } else {
      store.commit('SET_LOGGED_IN', false);
    }
  }
  if (to.name !== 'Login' && !store.getters.getLoggedIn) {
    const loggedIn = await api.isAuthenticated();
    if (loggedIn) {
      store.commit('SET_LOGGED_IN', true);
      next();
    }
    next({ name: 'Login' });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = 'Reimbursement';
  });
});
