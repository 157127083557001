import './plugins/bootstrap-vue';

import App from './App.vue';
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ui5-/];
Vue.config.devtools = true;

/* eslint-disable no-new */
new Vue({
  router,
  store: store,
  render: (h) => h(App),
}).$mount('#app');
