export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    isValid() {
      return (
        !this.required ||
        this.readonly ||
        (this.required && this.isValueValid(this.value))
      );
    },
  },
  methods: {
    isValueValid(val) {
      if (val?.length === 0) {
        return false;
      }

      if (this.isNumeric(val)) {
        return val >= 0;
      }

      return true;
    },
    isNumeric(val) {
      return !isNaN(val) && !isNaN(parseFloat(val));
    },
  },
};
