<template>
  <BaseModal
    :value="value"
    :header-text="fileName"
    scrollable
    stretch
    close-text="Back"
    @input="closeDialog"
  >
    <div style="width: 100%; overflow-y: scroll; padding: 15px 15px 4px 15px">
      <pre v-html="fileData"></pre>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '../components/common/BaseModal.vue';

export default {
  name: 'FinanceAuditFileModal',
  components: {
    BaseModal,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default: () => {
        return {
          fileName: '',
          fileData: '',
        };
      },
    },
  },

  data: () => ({
    fileData: '',
    fileName: '',
  }),
  watch: {
    file(newVal) {
      if (newVal) {
        this.showFinanceFile();
      }
    },
  },
  methods: {
    closeDialog() {
      this.currentTab = 0;
      this.tabsFinished = 0;
      this.$emit('input', false);
    },
    showFinanceFile() {
      if (!this.file) return;

      this.fileData =
        '<pre style="margin: auto;">' +
        this.file.fileData
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#039;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(+'/n/g', '\n')
          .replace(/\\n/g, '\n')
          .replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;') +
        '</pre>';

      this.fileName = this.file.fileName;

      this.$emit('input', true);
    },
  },
};
</script>

<style scoped>
.jumbotron {
  width: 100%;
  background-color: #e9ecef;
}
</style>
