<template>
  <ui5-toast ref="toast" :duration="toastDuration" placement="BottomCenter">
    <div>{{ toastMsg }}</div>
  </ui5-toast>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GlobalToast',
  computed: {
    ...mapState({
      toastMsg: 'toastMsg',
      toastDuration: 'toastDuration',
    }),
  },
  watch: {
    toastMsg(newVal) {
      if (newVal?.length > 0) {
        this.$refs.toast.show();
        this.resetToastMessage();
      }
    },
  },
  methods: {
    resetToastMessage() {
      setTimeout(() => {
        this.$store.commit('RESET_TOAST_MSG');
      }, this.toastDuration);
    },
  },
};
</script>

<style scoped></style>
