<template>
  <section>
    <b-row>
      <b-col cols="3">
        <b-row>
          <b-col>
            <TimeReportFilterCard 
            :from-date="searchFromDate" 
            :to-date="searchToDate" 
            @searchFilter="searchFilter"
            @clearFilter="clearFilter" 
            @set-from-date="setFromDate" 
            @set-to-date="setToDate" />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="9">
        <b-row>
          <b-col>
            <section v-if="isEmpty(timeReports)" class="section">
              <ui5-card class="medium">
                <ui5-card-header slot="header" title-text="Time Data Reports" />
                <div class="wrapper">
                  <ui5-busy-indicator id="busy-indicator" size="Medium" :active="busyLoading"
                    style="width: 100%; height: 100%;">
                    <p class="text-center" style="margin-top: 15px">
                      Use filters on the left panel to generate report.
                    </p>
                  </ui5-busy-indicator>
                </div>
              </ui5-card>
            </section>
            <TimeReportList 
            v-else 
            :time-reports="timeReports"
            :from-date="searchFromDate" 
            :to-date="searchToDate" 
            :employeeId="employeeId"
            style="width: 100%; height: 100%" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import api from '../api/index';
import TimeReportFilterCard from './TimeReportFilterCard.vue';
import TimeReportList from './TimeReportList.vue';
import { dkConstants } from '../dk-constants';
export default {
  name: 'TimeReport',
  components: {
    TimeReportFilterCard,
    TimeReportList,
  },

  data: function () {
    return {
      timeReports: {},
      searchFromDate: '',
      searchToDate: '',
      employeeId: '',
      busyLoading: false,
    };
  },
  mounted() {
    this.initDates();
  },
  methods: {
    searchFilter(filter) {
      this.busyLoading = true;
      this.employeeId = filter.employeeId;
      this.timeReports = {};
      api
        .getEmployeeTimeDataReport(filter)
        .then((response) => {
          this.timeReports = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.busyLoading = false;
        });
    },
    clearFilter() {
      this.timeReports = {};
      this.initDates();
    },
    setFromDate(newDate) {
      this.searchFromDate = newDate;
    },
    setToDate(newDate) {
      this.searchToDate = newDate;
    },
    initDates() {
      const today = new Date();
      const tempToDate = new Date(today);
      const tempFromDate = new Date(
        today.setDate(today.getDate() - dkConstants.FROM_DATE_DAYS_AGO)
      );

      this.searchFromDate = this.dateToString(tempFromDate);
      this.searchToDate = this.dateToString(tempToDate);
    },

    dateToString(tempDate) {
      const dateYear = tempDate.getFullYear();
      const dateMonth = dkConstants.MONTH_NUMBERS[tempDate.getMonth()];
      let dateDay = tempDate.getDate();
      if (dateDay < 10) {
        dateDay = '0' + dateDay;
      }
      return `${dateYear}-${dateMonth}-${dateDay}`;
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
  },
};
</script>

<style scoped></style>