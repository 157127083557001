<template>
  <section class="section">
    <ui5-card class="medium">
      <ui5-card-header
        slot="header"
        title-text="Filter options"
        subtitle-text="Search employee cases"
      >
        <ui5-icon slot="avatar" name="filter" />
      </ui5-card-header>

      <div class="wrapper">
        <!-- Dates -->
        <div class="form-group">
          <ui5-label
            for="fromDate"
            class="filter-input label"
            wrapping-type="Normal"
            >From Date
          </ui5-label>
          <input
            id="fromDate"
            type="date"
            class="filter-input datepicker"
            format-pattern="yyyy-MM-dd"
            style="width: 210px"
            :value="fromDate"
            @change="setFromDate"
          />
        </div>

        <div class="form-group">
          <ui5-label
            for="toDate"
            class="filter-input label"
            wrapping-type="Normal"
            >To Date
          </ui5-label>
          <input
            id="toDate"
            type="date"
            style="width: 210px"
            format-pattern="yyyy-MM-dd"
            class="filter-input datepicker"
            :value="toDate"
            @change="setToDate"
          />
        </div>

        <!-- Extra Options Select -->
        <div class="form-group">
          <ui5-label
            class="filter-input label"
            wrapping-type="Normal"
            for="filterInputOptions"
          >
            Extra Options
          </ui5-label>
          <ui5-select
            id="filterInputOptions"
            class="filter-input filter-select"
            @change="filterOptionChange"
          >
            <ui5-option
              v-for="filterOption in filterOptions"
              :key="filterOption.name"
            >
              {{ filterOption.name }}
            </ui5-option>
          </ui5-select>
        </div>

        <!-- Text Input -->
        <div v-if="filterInput || filterInputID">
          <div class="form-group">
            <ui5-label
              class="filter-input label"
              wrapping-type="Normal"
              for="filterInput"
            >
              {{ filterSelected }}
            </ui5-label>
            <ui5-input
              id="filterInput"
              class="filter-input"
              :placeholder="filterSelected"
            />
          </div>
        </div>

        <!-- Type Selection -->
        <div v-if="filterInputType">
          <div class="form-group">
            <ui5-label
              class="filter-input label"
              wrapping-type="Normal"
              for="filterInputType"
            >
              {{ filterSelected }}
            </ui5-label>
            <ui5-select id="filterInputType" class="filter-input filter-select">
              <ui5-option
                v-for="(type, index) in typeSelectListOptions"
                :key="index"
              >
                {{ type }}
              </ui5-option>
            </ui5-select>
          </div>
        </div>

        <!-- States Selection -->
        <div v-if="filterInputState" class="form-group">
          <div>
            <ui5-label
              class="filter-input label"
              wrapping-type="Normal"
              for="filterInputStates"
            >
              {{ filterSelected }}
            </ui5-label>
            <ui5-select
              id="filterInputStates"
              class="filter-input filter-select-complex"
            >
              <ui5-option
                v-for="(state, index) in statesSelectListOptions"
                :key="index"
              >
                {{ state }}
              </ui5-option>
            </ui5-select>
          </div>
        </div>

        <div v-if="filterInputState" class="form-group">
          <div>
              <ui5-label
                class="filter-input label"
                wrapping-type="Normal"
                for="filterInputForm"
              >
                Form
              </ui5-label>
              <ui5-select
                id="filterInputForm"
                class="filter-input filter-select-complex"
                @change="setForm"
              >
                <ui5-option value="" selected> All forms </ui5-option>
                <ui5-option
                  v-for="(selectedForm, index) in formSelectListOptions"
                  :key="index"
                  :value="selectedForm"
                >
                  {{ selectedForm }}
                </ui5-option>
              </ui5-select>
            </div>
          </div>

        <div class="filter-buttons">
          <ui5-button class="filter-button" icon="search" @click="buildFilter">
            Search
          </ui5-button>
          <ui5-button
            class="filter-button"
            icon="clear-filter"
            @click="clearFilter"
          >
            Clear Filter
          </ui5-button>
        </div>
      </div>
    </ui5-card>
  </section>
</template>

<script>
import api from '../api';
import { dkConstants } from '../dk-constants';
import { mapState } from 'vuex';

export default {
  name: 'FilterCard',
  props: {
    fromDate: {
      type: String,
      required: true,
    },
    toDate: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      filterOptions: dkConstants.FILTER_OPTIONS,
      typeSelectListOptions: [],
      statesSelectListOptions: [],
      formSelectListOptions: [],

      filterInput: false,
      filterInputType: false,
      filterInputState: false,
      filterInputID: false,
      form: '',
    };
  },
  computed: {
    ...mapState({
      filterSelected: (state) => state.home.selectedFilter,
      filterSelectedValue: (state) => state.home.selectedFilterValue,
    }),
  },

  created() {
    /**
     * Get options for the TypeSelectList
     */
    api
      .getTypeSelectListOptions()
      .then((response) => {
        this.typeSelectListOptions = response.data;
      })
      .catch((error) => {
        console.error(error);
      });

    /**
     * Get options for the StatesSelectList
     */
    api
      .getStatesSelectListOptions()
      .then((response) => {
        this.statesSelectListOptions = response.data;
      })
      .catch((error) => {
        console.error(error);
      }); 

    /**
     * Get options for the formSelectListOptions
     */
    api
      .getFormSelectListOptions()
      .then((response) => {
        this.formSelectListOptions = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    filterOptionChange(selectedOption) {
      this.$store.commit(
        'home/SET_SELECTED_FILTER',
        document
          .getElementById('filterInputOptions')
          .selectedOption.innerHTML.trim()
      );
      let filterSelectedValue = '';
      if (this.filterSelected === 'Type') {
        this.filterInput = false;
        this.filterInputType = true;
        this.filterInputState = false;
        filterSelectedValue = this.typeSelectListOptions[0];
        this.filterInputID = false;
        this.form = '';
      } else if (this.filterSelected === 'State') {
        this.filterInput = false;
        this.filterInputType = false;
        this.filterInputState = true;
        filterSelectedValue = this.statesSelectListOptions[0];
        this.filterInputID = false;
        this.form = '';
      } else if (this.filterSelected === 'None') {
        this.filterInput = false;
        this.filterInputType = false;
        this.filterInputState = false;
        this.filterSelected = '';
        this.filterInputID = false;
        this.form = '';
      } else if (this.filterSelected === 'ID') {
        this.filterInput = false;
        this.filterInputType = false;
        this.filterInputState = false;
        filterSelectedValue = '';
        this.filterInputID = true;
        this.form = '';
      } else if (this.filterSelected === 'Name') {
        this.filterInput = true;
        this.filterInputType = false;
        this.filterInputState = false;
        filterSelectedValue = '';
        this.filterInputID = false;
        this.form = '';
      } else {
        this.filterInput = true;
        this.filterInputType = false;
        this.filterInputState = false;
        filterSelectedValue = '';
        this.filterInputID = false;
        this.form = '';
      }
      this.$store.commit('home/SET_SELECTED_FILTER_VALUE', filterSelectedValue);
    },

    setForm() {
      this.form = document.getElementById('filterInputForm').selectedOption.value.trim();
    },

    /**
     * Build the filter object that is passed to the backend
     */
    buildFilter() {
      this.setFilterValue();
      const filter = {
        fromDate: this.fromDate,
        toDate: (() => {
          const tempDate = new Date(this.toDate);
          tempDate.setDate(tempDate.getDate() + 1);
          return tempDate.toISOString().split('T')[0];
        })()
      };
      if (this.filterSelected !== '') {
        filter['filter'] = this.filterSelected;
      }
      if (this.filterSelectedValue !== '') {
        filter['filterValue'] = this.filterSelectedValue;
      }
      if (this.form !== ''){
        filter['formValue'] = this.form;
      }
      this.$emit('searchFilter', filter);
    },

    /**
     * Clear the current values for the filter
     */
    clearFilter() {
      const radios = document.getElementsByClassName('filterOption');
      for (let i = 0; i < radios.length; i++) {
        radios[i].removeAttribute('selected');
      }

      // reset all the ui5-select elements by selecting the default option (index 0)
      [
        ...document.getElementsByClassName('filter-input filter-select'),
      ].forEach((input) => {
        input._select(0);
      });

      this.$store.commit('home/RESET_SELECTED_FILTER');
      this.$store.commit('home/RESET_SELECTED_FILTER_VALUE');
      this.filterInput = false;
      this.filterInputState = false;
      this.filterInputType = false;
      this.filterInputID = false;
      this.$emit('clearFilter', {});
    },

    setFromDate(e) {
      this.$emit('set-from-date', e.target.value);
    },
    setToDate(e) {
      this.$emit('set-to-date', e.target.value);
    },

    setFilterValue() {
      if (this.filterSelected !== '') {
        if (this.filterSelected === 'Type') {
          this.$store.commit(
            'home/SET_SELECTED_FILTER_VALUE',
            document
              .getElementById('filterInputType')
              .selectedOption.innerHTML.trim()
          );
        } else if (this.filterSelected == 'State') {
          this.$store.commit(
            'home/SET_SELECTED_FILTER_VALUE',
            document
              .getElementById('filterInputStates')
              .selectedOption.innerHTML.trim()
          );
        } else {
          this.$store.commit(
            'home/SET_SELECTED_FILTER_VALUE',
            document.getElementById('filterInput').value
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.form-group {
  float: left;
  display: block;
}

.filter-input {
  display: table-cell;
  width: 6rem;
  vertical-align: middle;
}

.label {
  text-align: left;
  padding-right: 0.5rem;
}

.filter-buttons {
  width: 100%;
  text-align: center;
  padding: 0rem 0.25rem 0.5rem 0.25rem;
}

.filter-button {
  width: 45%;
}
</style>
