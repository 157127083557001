<template>
  <BaseModal
    :value="showDialog"
    close-text="OK"
    header-text="Error"
    @input="handleClose"
  >
    <component
      :is="error.component"
      v-for="(error, idx) in errors"
      :id="String(idx)"
      :key="idx"
      v-bind="error.props"
      @close="removeError(idx)"
    />
  </BaseModal>
</template>

<script>
import MessageStrip from '../components/MessageStrip.vue';
import BaseModal from '../components/common/BaseModal.vue';
import { mapState } from 'vuex';

export default {
  name: 'ErrorModal',
  components: {
    MessageStrip,
    BaseModal,
  },
  computed: {
    ...mapState({
      showDialog: 'showGlobalDialog',
      errors: 'errors',
    }),
  },
  methods: {
    removeError(errorIdx) {
      this.$store.commit('REMOVE_ERROR', errorIdx);
      if (this.errors.length === 0) {
        this.handleClose();
      }
    },
    handleClose() {
      this.$store.dispatch('resetDialog');
    },
  },
};
</script>
