<template>
  <section class="section">
    <ui5-card class="medium">
      <ui5-card-header
        slot="header"
        title-text="Filter options"
        subtitle-text="Search case states"
      >
        <ui5-icon slot="avatar" name="filter" />
      </ui5-card-header>

      <div class="wrapper">
        <form id="report-filter-form">
          <!-- Dates -->
          <div class="form-group">
            <ui5-label
              for="fromDate"
              class="filter-input label"
              wrapping-type="Normal"
              editable="true"
              >From Date</ui5-label
            >
            <input
              id="fromDate"
              type="date"
              for="fromDate"
              class="filter-input datepicker"
              format-pattern="yyyy-MM-dd"
              style="width: 210px"
              @change="setFromDate"
            />
          </div>

          <div class="form-group">
            <ui5-label
              for="toDate"
              class="filter-input label"
              wrapping-type="Normal"
              >To Date</ui5-label
            >
            <input
              id="toDate"
              type="date"
              for="fromDate"
              class="filter-input datepicker"
              format-pattern="yyyy-MM-dd"
              style="width: 210px"
              @change="setToDate"
            />
          </div>

          <!-- case status -->
          <div class="form-group">
            <ui5-label
              class="filter-input label"
              wrapping-type="Normal"
              for="caseStatus"
            >
              Case Status
            </ui5-label>
            <ui5-select
              id="caseStatus"
              class="filter-input filter-select"
              @change="setCaseStatus"
            >
              <option value="" selected>All case statuses</option>
              <ui5-option
                v-for="(status, index) in statusSelectListOptions"
                :key="index"
                :value="status"
              >
                {{ status }}
              </ui5-option>
            </ui5-select>
          </div>

          <!-- case type -->
          <div class="form-group">
            <ui5-label
              class="filter-input label"
              wrapping-type="Normal"
              for="caseType"
            >
              Case Type
            </ui5-label>
            <ui5-select
              id="caseType"
              class="filter-input filter-select"
              @change="setCaseType"
            >
              <ui5-option value="" selected> All case types </ui5-option>
              <ui5-option
                v-for="(type, index) in typeSelectListOptions"
                :key="index"
                :value="type"
              >
                {{ type }}
              </ui5-option>
            </ui5-select>
          </div>

          <!-- form -->
          <div class="form-group">
            <ui5-label
              class="filter-input label"
              wrapping-type="Normal"
              for="form"
            >
              Form
            </ui5-label>
            <ui5-select
              id="form"
              class="filter-input filter-select"
              @change="setForm"
            >
              <ui5-option value="" selected> All forms </ui5-option>
              <ui5-option
                v-for="(selectedForm, index) in formSelectListOptions"
                :key="index"
                :value="selectedForm"
              >
                {{ selectedForm }}
              </ui5-option>
            </ui5-select>
          </div>

          <!-- response message category -->
          <div class="form-group">
            <ui5-label
              class="filter-input label"
              wrapping-type="Normal"
              for="responseCategory"
            >
              Response Category
            </ui5-label>
            <ui5-select
              id="responseCategory"
              class="filter-input filter-select"
              @change="setResponseCategory"
            >
              <ui5-option value="" selected> All categories </ui5-option>
              <ui5-option
                v-for="(
                  category, index
                ) in filteredResponseCategorySelectListOptions"
                :key="index"
                :value="category"
              >
                {{ category }}
              </ui5-option>
            </ui5-select>
          </div>

          <!-- company code -->
          <div class="form-group">
            <ui5-label
              class="filter-input label"
              wrapping-type="Normal"
              for="companyCodes"
            >
              Company Codes
            </ui5-label>
            <!-- ensure the event handler (@*) is the same as the id) -->
            <SelectMultiple
              id="companyCodes"
              key="companyCodes"
              class="filter-input"
              title="Company Codes"
              subheading="Add multiple company codes"
              :options="[]"
              :selected-items="companyCodes"
              @companyCodes="setCompanyCodes"
            />
          </div>

          <!-- p number -->
          <div class="form-group">
            <ui5-label
              class="filter-input label"
              wrapping-type="Normal"
              for="pNumbers"
            >
              P Numbers
            </ui5-label>
            <!-- ensure the event handler (@*) is the same as the id -->
            <SelectMultiple
              id="pNumbers"
              key="pNumbers"
              class="filter-input"
              title="P Numbers"
              subheading="Add multiple p numbers"
              :options="[]"
              :selected-items="pNumbers"
              @pNumbers="setPNumbers"
            />
          </div>
        </form>
        <div class="filter-buttons">
          <ui5-button class="filter-button" icon="search" @click="buildFilter">
            Search
          </ui5-button>
          <ui5-button
            class="filter-button"
            icon="clear-filter"
            @click="clearFilter"
          >
            Clear Filter
          </ui5-button>
        </div>
      </div>
    </ui5-card>
  </section>
</template>

<script>
import api from '../api/index';
import SelectMultiple from './SelectMultiple';
import { dkConstants } from '../dk-constants';

export default {
  name: 'ReportFilterCard',
  components: {
    SelectMultiple,
  },
  data: function () {
    return {
      statusSelectListOptions: [],
      typeSelectListOptions: [],
      formSelectListOptions: [],
      responseCategorySelectListOptions: [],
      // filter values
      fromDate: '',
      toDate: '',
      caseStatus: '',
      caseType: '',
      form: '',
      responseCategory: '',
      companyCodes: [],
      pNumbers: [],
      selected: [],
      // TODO: include response message category
    };
  },
  computed: {
    filteredResponseCategorySelectListOptions() {
      return this.responseCategorySelectListOptions.filter(
        (option) => option && option !== '' && option !== 'null'
      );
    },
  },

  created() {
    /**
     * Get options for the TypeSelectList
     */
    api
      .getTypeSelectListOptions()
      .then((response) => {
        this.typeSelectListOptions = response.data;
      })
      .catch((error) => {
        console.error(error);
      });

    /**
     * Get options for the StatesSelectList
     */
    api
      .getStatesSelectListOptions()
      .then((response) => {
        this.statusSelectListOptions = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
    /**
     * Get options for the formSelectListOptions
     */
    api
      .getFormSelectListOptions()
      .then((response) => {
        this.formSelectListOptions = response.data;
      })
      .catch((error) => {
        console.error(error);
      });

    api
      .getResponseCategorySelectListOptions()
      .then((response) => {
        this.responseCategorySelectListOptions = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },

  mounted() {
    this.initDates();
  },

  methods: {
    buildFilter() {
      if (this.fromDate == '' && this.toDate == '') {
        console.error(
          "Filter requires either the 'From Date' or the 'To Date'. Select atleast one date filter"
        );
        return;
      }
      if (this.toDate < this.fromDate) {
        console.error(
          "'From date' cannot be later than 'To Date'. Try swapping the date filters"
        );
        return;
      }

      const filterData = {};
      // set properties to null if they are empty strings
      filterData.startDate = this.fromDate == '' ? null : this.fromDate;
      filterData.endDate = this.toDate == '' ? null : this.toDate;
      filterData.caseStatus = this.caseStatus == '' ? null : this.caseStatus;
      filterData.caseType = this.caseType == '' ? null : this.caseType;
      filterData.form = this.form == '' ? null : this.form;
      filterData.companyCodes = this.companyCodes;
      filterData.pNumbers = this.pNumbers;
      filterData.responseMessageCategory =
        this.responseCategory == '' ? null : this.responseCategory;

      this.$emit('searchFilter', filterData);
    },

    clearFilter() {
      // clear input elements
      this.initDates();
      this.caseStatus = '';
      this.caseType = '';
      this.form = '';
      this.companyCodes = [];
      this.pNumbers = [];

      // reset all the ui5-select elements by selecting the default option (index 0)
      [
        ...document.getElementsByClassName('filter-input filter-select'),
      ].forEach((input) => {
        input._select(0);
      });

      this.$emit('clearFilter', {});
    },

    setFromDate() {
      this.fromDate = document.getElementById('fromDate').value;
    },

    setToDate() {
      this.toDate = document.getElementById('toDate').value;
    },

    setCaseStatus() {
      this.caseStatus = document
        .getElementById('caseStatus')
        .selectedOption.value.trim();
    },

    setCaseType() {
      this.caseType = document
        .getElementById('caseType')
        .selectedOption.value.trim();
    },

    setForm() {
      this.form = document.getElementById('form').selectedOption.value.trim();
    },

    setResponseCategory() {
      this.responseCategory = document
        .getElementById('responseCategory')
        .selectedOption.value.trim();
    },

    setCompanyCodes(payload) {
      this.companyCodes = payload;
    },

    setPNumbers(payload) {
      this.pNumbers = payload;
    },

    initDates() {
      const monthNumbers = dkConstants.MONTH_NUMBERS;
      const today = new Date();
      const tempToDate = new Date(today);
      const tempFromDate = new Date(
        today.setDate(today.getDate() - dkConstants.FROM_DATE_DAYS_AGO)
      );

      let fromDateDay = tempFromDate.getDate();
      if (fromDateDay < 10) {
        fromDateDay = '0' + fromDateDay;
      }

      let toDateDay = tempToDate.getDate();
      if (toDateDay < 10) {
        toDateDay = '0' + toDateDay;
      }

      this.fromDate =
        tempFromDate.getFullYear() +
        '-' +
        monthNumbers[tempFromDate.getMonth()] +
        '-' +
        fromDateDay;
      document.getElementById('fromDate').value = this.fromDate;
      this.toDate =
        tempToDate.getFullYear() +
        '-' +
        monthNumbers[tempToDate.getMonth()] +
        '-' +
        toDateDay;
      document.getElementById('toDate').value = this.toDate;
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.form-group {
  float: left;
  display: block;
}

.filter-input {
  display: table-cell;
  width: 6rem;
  vertical-align: middle;
}

.label {
  text-align: left;
  padding-right: 0.5rem;
}

.filter-buttons {
  width: 100%;
  text-align: center;
  padding: 0rem 0.25rem 0.5rem 0.25rem;
}

.filter-button {
  width: 45%;
}
</style>
