<template>
  <section class="section">
    <base-config-card title="Configuration">
      <ui5-busy-indicator
        size="Medium"
        class="full-width"
        :active="configLoading"
      >
        <ui5-table class="content-padding">
          <ui5-table-column slot="columns">
            <ui5-label class="text-right">App Key</ui5-label>
          </ui5-table-column>
          <ui5-table-column slot="columns">
            <ui5-label>Description</ui5-label>
          </ui5-table-column>
          <ui5-table-column slot="columns">
            <ui5-label>Value</ui5-label>
          </ui5-table-column>
          <ui5-table-column slot="columns">
            <ui5-label>Configure</ui5-label>
          </ui5-table-column>
          <ui5-table-row v-for="(r, idx) in rows" :key="idx">
            <ui5-table-cell>{{ r.appKey }}</ui5-table-cell>
            <ui5-table-cell>{{ r.description }}</ui5-table-cell>
            <ui5-table-cell>{{ r.value }}</ui5-table-cell>
            <ui5-table-cell>
              <ui5-button class="mt-1" @click="editValue(r)"> Edit </ui5-button>
            </ui5-table-cell>
          </ui5-table-row>
        </ui5-table>
      </ui5-busy-indicator>
    </base-config-card>

    <base-config-card
      title="Test Run Values"
      subtitle="Set values to use when program in test environment"
    >
      <ui5-table class="content-padding">
        <ui5-table-column slot="columns">
          <ui5-label>Description</ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns">
          <ui5-label>Value</ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns">
          <ui5-label>Configure</ui5-label>
        </ui5-table-column>
        <ui5-table-row>
          <ui5-table-cell>Run As Of Date</ui5-table-cell>
          <ui5-table-cell>{{ runAsOfDate }}</ui5-table-cell>
          <ui5-table-cell>
            <input
              id="testDate"
              type="date"
              class="filter-input datepicker"
              format-pattern="yyyy-MM-dd"
              :value="runAsOfDate"
              style="width: 210px"
              @change="setRunAsOfDate"
            />
          </ui5-table-cell>
        </ui5-table-row>
        <ui5-table-row>
          <ui5-table-cell>Absence Last Checked Date</ui5-table-cell>
          <ui5-table-cell>{{ absenceLastCheckedDate }}</ui5-table-cell>
          <ui5-table-cell>
            <input
              id="absenceLastCheckedDate"
              type="datetime-local"
              class="filter-input datepicker"
              format-pattern="yyyy-MM-dd HH:mm:ss"
              :value="absenceLastCheckedDate"
              style="width: 210px"
              @change="setAbsenceLastCheckedDate"
            />
          </ui5-table-cell>
        </ui5-table-row>
      </ui5-table>
      <ui5-button @click="refresh"> Refresh </ui5-button>
      <ui5-button @click="startTest"> Start Test </ui5-button>
    </base-config-card>

    <base-config-card
      title="Rate Configuration"
      subtitle="Configure hourly rates used for estimated reimbursement amounts"
    >
      <rate-config-card
        :rates="orderedRateList"
        :loading="rateListLoading"
        @save-new-rate="saveNewRate"
        @save-edited-rate="saveEditedRate"
      />
    </base-config-card>
    <base-config-card
      title="Delete Cases"
      subtitle="Delete cases by employee ID."
    >
      <div class="py-2">
        <div class="pl-2">
          Enter the employee ID to delete cases for and click Delete.
        </div>
      </div>
      <ui5-select
        id="employeeId"
        class="modal-input modal-select"
        placeholder="Employee ID"
        @change="setEmployeeId"
      >
        <ui5-option
          v-for="employee in employees"
          :key="employee.employeeId"
          :value="employee.employeeId"
          class="modal-select-option"
        >
          {{ employee.employeeId }}
        </ui5-option>
      </ui5-select>
      <br />
      <ui5-button @click="deleteCases"> Delete </ui5-button>
    </base-config-card>
    <base-config-card
      title="Create Case"
      subtitle="Upload Absence and Planned hours CSV to create new cases."
    >
      <b-container fluid class="p-0">
        <div class="py-2">
          <div class="pl-2">
            Select the CSV files to upload and click Upload.
          </div>
        </div>
        <ui5-table class="content-padding">
          <ui5-table-column slot="columns">
            <ui5-label>Description</ui5-label>
          </ui5-table-column>
          <ui5-table-column slot="columns">
            <ui5-label>Example</ui5-label>
          </ui5-table-column>
          <ui5-table-column slot="columns">
            <ui5-label>Progress</ui5-label>
          </ui5-table-column>
          <ui5-table-column slot="columns">
            <ui5-label class="text-right">Select</ui5-label>
          </ui5-table-column>

          <ui5-table-row>
            <ui5-table-cell>Absence Hours</ui5-table-cell>
            <ui5-table-cell>
              <p>
                Date,EmployeeID,TimeType,Hours
                <br />
                15-01-2023,12345,0500,8.0
                <br />
                15-01-2023,12345,0500,8.0
              </p>
            </ui5-table-cell>
            <ui5-table-cell> {{ absenceProgressString }} </ui5-table-cell>
            <ui5-table-cell>
              <ui5-file-uploader
                id="absenceFileUploader"
                accept=".csv"
                multiple="false"
                @change="handleAbsenceChange"
              >
                <ui5-button> Select Absence hours </ui5-button>
              </ui5-file-uploader>
            </ui5-table-cell>
          </ui5-table-row>

          <ui5-table-row>
            <ui5-table-cell>Planned Hours</ui5-table-cell>
            <ui5-table-cell>
              <p>
                Date,EmployeeID,Hours
                <br />
                15-01-2023,12345,2.0
                <br />
                15-01-2023,12345,2.0
              </p>
            </ui5-table-cell>
            <ui5-table-cell> {{ plannedProgressString }} </ui5-table-cell>
            <ui5-table-cell>
              <ui5-file-uploader
                id="plannedFileUploader"
                accept=".csv"
                multiple="false"
                @change="handlePlannedChange"
              >
                <ui5-button> Select Planned hours </ui5-button>
              </ui5-file-uploader>
            </ui5-table-cell>
          </ui5-table-row>
        </ui5-table>
        <ui5-button
          :disabled="!absenceReady && !plannedReady"
          @click="uploadFiles"
        >
          Upload
        </ui5-button>
      </b-container>
    </base-config-card>

    <ConfigModal
      v-model="configModalShown"
      :config-data="selectedItem"
      :form-data="formData"
      :schema="schema"
      @submit-form="submitForm"
    />
  </section>
</template>

<script>
import api from '../api';
import ConfigModal from './ConfigModal.vue';
import RateConfigCard from './config/RateConfigCard.vue';
import BaseConfigCard from './config/BaseConfigCard.vue';
import { updateListElement } from '../utils.js';

export default {
  name: 'ConfigPage',
  components: {
    BaseConfigCard,
    RateConfigCard,
    ConfigModal,
  },
  data() {
    return {
      absenceFile: null,
      plannedFile: null,
      absenceReady: false,
      plannedReady: false,
      absenceProgress: 0,
      plannedProgress: 0,
      rows: [],
      runAsOfDate: '',
      absenceLastCheckedDate: '',
      schema: {},
      formData: {},
      dataToEdit: {},
      configModalShown: false,
      selectedItem: null,
      configLoading: false,
      rateList: [],
      rateListLoading: false,
      employeeId: '',
      employees: [],
    };
  },
  computed: {
    orderedRateList() {
      return [...this.rateList].sort((r1, r2) => r2.startDate - r1.startDate);
    },
    absenceProgressString() {
      if (this.absenceProgress === 0) {
        return 'Ready';
      } else if (this.absenceProgress === 1) {
        return 'Busy';
      } else if (this.absenceProgress === 2) {
        return 'Done';
      } else if (this.absenceProgress === 3) {
        return 'Failed';
      } else {
        return 'Error';
      }
    },
    plannedProgressString() {
      if (this.plannedProgress === 0) {
        return 'Ready';
      } else if (this.plannedProgress === 1) {
        return 'Busy';
      } else if (this.plannedProgress === 2) {
        return 'Done';
      } else if (this.plannedProgress === 3) {
        return 'Failed';
      } else {
        return 'Error';
      }
    },
  },
  async created() {
    await Promise.all([this.refresh(), this.getConfigData()]);
  },
  methods: {
    editValue(r) {
      this.formData = {};
      this.dataToEdit = r;

      api
        .getConfigSchema(r)
        .then((response) => {
          this.schema = response.data;
          this.formData[this.schema.name] = this.schema.value;
          this.selectedItem = r;
          this.configModalShown = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setEmployeeId(e) {
      this.employeeId = e.target.selectedOption?.value;
    },

    deleteCases() {
      api
        .deleteCases(this.employeeId)
        .then(async (response) => {
          await this.$store.dispatch(
            'showToastMessage',
            'Cases & Time data deleted successfully for employee ' +
              this.employeeId
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },

    submitForm(formData) {
      this.dataToEdit.value = formData[this.schema.name];

      api.updateConfigValue(this.dataToEdit).catch((error) => {
        console.error(error);
      });
    },

    setRunAsOfDate(e) {
      this.runAsOfDate = e.target.value;
    },

    setAbsenceLastCheckedDate() {
      this.absenceLastCheckedDate = document
        .getElementById('absenceLastCheckedDate')
        .value.replace(' ', 'T');
    },

    async getRunAsOfDate() {
      try {
        const response = await api.getRunAsOfDate();
        this.runAsOfDate = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAbsenceLastCheckedDate() {
      try {
        const response = await api.getAbsenceLastCheckedDate();
        this.absenceLastCheckedDate = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getEmployeeList() {
      try {
        const response = await api.getAllEmployees();
        this.employees = response.data.sort((a, b) => {
          return a.employeeId - b.employeeId;
        });
        this.employeeId = this.employees[0].employeeId || null;
      } catch (error) {
        console.error(error);
      }
    },
    async getConfigData() {
      try {
        this.configLoading = true;
        const response = await api.getConfigData();
        this.rows = response.data.filter((u) => {
          return u.category === 'CLIENT_EDITABLE';
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.configLoading = false;
      }
    },

    handleAbsenceChange: function (event) {
      try {
        this.absenceProgress = 0;
        this.absenceFile = event.target.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          const txt = e.target.result;
          const headers = txt.split('\n')[0];
          if (
            headers.indexOf('Date') === -1 &&
            headers.indexOf('EmployeeID') === -1 &&
            headers.indexOf('TimeType') === -1 &&
            headers.indexOf('Hours') === -1
          ) {
            alert('Invalid file format');
            return;
          }
          const line1 = txt.split('\n')[1];
          if (line1.indexOf(';') === -1) {
            alert('Invalid file format');
            return;
          }
          this.absenceReady = true;
        };
        reader.readAsText(this.absenceFile);
      } catch (error) {
        alert('Invalid file format');
      }
    },

    handlePlannedChange: function (event) {
      try {
        this.plannedProgress = 0;
        this.plannedFile = event.target.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          const txt = e.target.result;
          const headers = txt.split('\n')[0];
          if (
            headers.indexOf('Date') === -1 &&
            headers.indexOf('EmployeeID') === -1 &&
            headers.indexOf('Hours') === -1
          ) {
            alert('Invalid file format');
            return;
          }
          const line1 = txt.split('\n')[1];
          if (line1.indexOf(';') === -1) {
            alert('Invalid file format');
            return;
          }
          this.plannedReady = true;
        };
        reader.readAsText(this.plannedFile);
      } catch (error) {
        alert('Invalid file format');
      }
    },

    async upload(formData, type) {
      const response = await api
        .uploadFile(formData)
        .then(async (response) => {
          await this.$store.dispatch(
            'showToastMessage',
            'Successfully Uploaded ' + type
          );
        })
        .catch(async (error) => {
          if (type === 'Absence Hours') this.absenceProgress = 3;
          else this.plannedProgress = 3;
          await this.$store.dispatch(
            'showToastMessage',
            'Failed To Upload ' + type
          );
          console.error(error);
        });

      return response;
    },

    async uploadFiles() {
      if (this.absenceFile) {
        this.absenceProgress = 1;
        const formData = new FormData();
        formData.append('file', this.absenceFile, 'absence.csv');
        await this.upload(formData, 'Absence Hours');
        this.absenceProgress = 2;
        this.absenceFile = null;
      }
      if (this.plannedFile) {
        this.plannedProgress = 1;
        const formData = new FormData();
        formData.append('file', this.plannedFile, 'planned.csv');
        await this.upload(formData, 'Planned Hours');
        this.plannedProgress = 2;
        this.plannedFile = null;
      }
      this.absenceReady = false;
      this.plannedReady = false;
    },

    startTest() {
      api
        .startTest(this.runAsOfDate, this.absenceLastCheckedDate)
        .catch((error) => {
          console.error(error);
        });
    },
    async getRateList() {
      try {
        this.rateListLoading = true;
        const response = await api.getRateList();
        this.rateList = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.rateListLoading = false;
      }
    },
    async refresh() {
      return Promise.all([
        this.getRunAsOfDate(),
        this.getAbsenceLastCheckedDate(),
        this.getRateList(),
        this.getEmployeeList(),
      ]);
    },
    async saveNewRate(rateValue) {
      try {
        this.rateListLoading = true;
        const response = await api.postNewHourlyRate(rateValue);
        this.rateList = response.data;
        await this.$store.dispatch('showToastMessage', 'Saved Latest Rate');
      } catch (error) {
        console.error('Failed to save', error);
      } finally {
        this.rateListLoading = false;
      }
    },
    async saveEditedRate(rate) {
      try {
        this.rateListLoading = true;
        const response = await api.updateHourlyRate(rate);
        updateListElement(this.rateList, response.data);
        await this.$store.dispatch('showToastMessage', 'Rate Updated');
      } finally {
        this.rateListLoading = false;
      }
    },
  },
};
</script>
