<template>
  <section>
    <b-row>
      <b-col lg="3" cols="12">
        <b-row>
          <b-col>
            <ReportFilterCard
              @searchFilter="searchFilter"
              @clearFilter="clearFilter"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <section class="section">
              <ui5-card class="medium">
                <ui5-card-header
                  slot="header"
                  title-text="Case states report"
                  subtitle-text="Limited cases listed, export to excel for full report"
                />
                <div class="wrapper">
                  <ui5-busy-indicator
                    :active="loading"
                    size="Medium"
                    style="width: 100%"
                  >
                    <CaseStatesReportList
                      v-if="caseStates.length > 0"
                      :case-states="caseStates"
                      @exportExcel="generateExcelReport"
                    />
                    <p v-else class="text-center" style="margin-top: 15px">
                      Use filters on the left panel to generate report.
                    </p>
                  </ui5-busy-indicator>
                </div>
              </ui5-card>
            </section>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import api from '../api/index';
import ReportFilterCard from './ReportFilterCard.vue';
import CaseStatesReportList from './CaseStatesReportList.vue';

export default {
  name: 'ReportPage',
  components: {
    ReportFilterCard,
    CaseStatesReportList,
  },

  data: function () {
    return {
      caseStates: [],
      filterData: {}, // set by the searchFilter function,
      loading: false,
    };
  },
  methods: {
    /**
     * get full report matching the filters and download as excel file
     */
    generateExcelReport() {
      this.loading = true;
      api
        .downloadExcelReport(this.filterData)
        .then((response) => {
          /**
           * We do not expect empty results here because the button is only displayed
           * if there is data matching the filters
           */
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const link = document.createElement('a');
          link.href = encodeURI(URL.createObjectURL(blob));
          link.setAttribute('download', 'reimbursement_cases_report.xlsx');
          link.setAttribute('style', 'display: none');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    searchFilter(filter) {
      this.loading = true;
      this.caseStates = [];
      this.filterData = filter;
      api
        .getCaseStatesReportData(filter)
        .then((response) => {
          /**
           * if response is null, that means no results match the applied filter,
           * leave this.caseStates = [], otherwise set the array to the data returned
           */
          if (response != null && response.data.length > 0) {
            this.caseStates = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * clear report filters
     */
    clearFilter() {
      this.caseStates = [];
      this.filterData = {};
    },
  },
};
</script>

<style scoped>
.export-button {
  margin-top: 15px;
}
</style>
