<template>
  <b-container fluid class="p-0">
    <b-row no-gutters class="align-items-center">
      <b-col cols="10">
        <div class="py-2">
          The latest rate will be used to calculate the estimate for all amounts
          from the start date onwards.<br />
          For overlapping rates, the most recently modified rate will be used
          when calculating estimates.
        </div>
      </b-col>
      <b-col cols="2" class="text-right">
        <ui5-button
          :disabled="addingNewRate || rateToEdit"
          tooltip="Define the value for a new rate covering today to the future"
          @click="addingNewRate = true"
        >
          Add Latest Rate
        </ui5-button>
      </b-col>
      <b-col cols="12">
        <ui5-busy-indicator class="d-block" :active="loading">
          <ui5-table no-data-text="No rates defined">
            <ui5-table-column
              v-for="(col, idx) in displayedHeaders"
              slot="columns"
              :key="idx"
            >
              <ui5-label>{{ col.text }}</ui5-label>
            </ui5-table-column>
            <ui5-table-row v-if="addingNewRate">
              <ui5-table-cell class="text-black-50">Latest</ui5-table-cell>
              <ui5-table-cell>Today</ui5-table-cell>
              <ui5-table-cell></ui5-table-cell>
              <ui5-table-cell></ui5-table-cell>
              <ui5-table-cell>
                <b-row no-gutters>
                  <b-col cols="12">
                    <NumberInput
                      ref="newRate"
                      label="Rate"
                      required
                      name="rate"
                      hide-label
                      :value="newRateValue"
                      @input="(val) => (newRateValue = Number(val))"
                    />
                  </b-col>
                </b-row>
              </ui5-table-cell>
              <ui5-table-cell>
                <b-row no-gutters class="mt-2">
                  <b-col>
                    <ui5-button
                      :disabled="!newRateIsValid"
                      @click="saveNewRate"
                    >
                      Save
                    </ui5-button>
                    <ui5-button @click="resetAddNewRate"> Cancel </ui5-button>
                  </b-col>
                </b-row>
              </ui5-table-cell>
            </ui5-table-row>
            <ui5-table-row v-for="(rate, idx) in rates" :key="`rate-${idx}`">
              <ui5-table-cell class="text-black-50">
                {{ !rate.endDate && !addingNewRate ? 'Latest' : '' }}
              </ui5-table-cell>
              <ui5-table-cell class="date-cell">
                {{ rate.startDate }}
              </ui5-table-cell>
              <ui5-table-cell class="date-cell">
                {{ rate.endDate || 'Present' }}
              </ui5-table-cell>
              <ui5-table-cell>
                {{ parseUTCDate(rate.lastModifiedDate) }}
              </ui5-table-cell>
              <ui5-table-cell class="text-left">
                {{ rate.value.toFixed(dkConstants.HOURLY_RATE_DECIMAL_PLACES) }}
              </ui5-table-cell>
              <ui5-table-cell>
                <b-row no-gutters class="mt-2">
                  <b-col>
                    <ui5-button
                      v-if="rateToEdit !== rate"
                      :disabled="rateToEdit || addingNewRate"
                      class="action-btn"
                      @click="editRate(rate)"
                    >
                      Edit
                    </ui5-button>
                  </b-col>
                </b-row>
              </ui5-table-cell>
            </ui5-table-row>
          </ui5-table>
        </ui5-busy-indicator>
      </b-col>
    </b-row>
    <rate-edit-modal
      :value="editRateModalShown"
      :original-rate="rateToEdit"
      @input="handleModalClose"
      @save-rate="saveEditedRate"
    />
  </b-container>
</template>

<script>
import RateEditModal from './RateEditModal.vue';
import { dkConstants } from '../../dk-constants';

export default {
  name: 'RateConfigCard',
  components: { RateEditModal },
  props: {
    rates: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      {
        fieldName: 'id',
        hidden: true,
      },
      {
        fieldName: 'isLatest',
        text: '',
      },
      {
        fieldName: 'startDate',
        text: 'Start Date',
      },
      {
        fieldName: 'endDate',
        text: 'End Date',
      },
      {
        fieldName: 'lastModifiedDate',
        text: 'Modified Date',
      },
      {
        fieldName: 'value',
        text: 'Hourly Rate',
      },
      {
        fieldName: 'actions',
        text: 'Actions',
      },
    ],
    rateToEdit: null,
    editRateModalShown: false,
    addingNewRate: false,
    newRateValue: 0.0,
    newRateIsValid: false,
  }),
  computed: {
    dkConstants() {
      return dkConstants;
    },
    displayedHeaders() {
      return this.headers.filter((h) => !h.hidden);
    },
  },
  watch: {
    addingNewRate(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.newRateIsValid = this.$refs.newRate?.isValid;
        });
      } else {
        this.newRateIsValid = false;
      }
    },
  },
  methods: {
    saveNewRate() {
      this.$emit('save-new-rate', this.newRateValue);
      this.addingNewRate = false;
      this.newRateValue = 0.0;
    },
    resetAddNewRate() {
      this.addingNewRate = false;
      this.newRateValue = 0.0;
    },
    parseUTCDate(utcDate) {
      return utcDate.slice(0, 19);
    },
    editRate(rate) {
      this.rateToEdit = { ...rate };
      this.editRateModalShown = true;
    },
    handleModalClose(isOpen) {
      if (!isOpen) {
        this.rateToEdit = null;
      }
      this.editRateModalShown = isOpen;
    },
    saveEditedRate(rate) {
      this.$emit('save-edited-rate', rate);
      this.handleModalClose(false);
    },
  },
};
</script>

<style scoped>
.action-btn {
  width: 80px;
}

.date-cell {
  width: 220px;
}
</style>
