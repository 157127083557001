<template>
  <BaseModal
    :value="value"
    :header-text="modalTitle"
    scrollable
    close-text="Cancel"
    @input="closeDialog"
  >
    <div class="jumbotron">
      <component
        :is="schema.fieldType"
        ref="inputField"
        :value="inputValue"
        v-bind="schema"
        @input="updateForm(schema.name, $event)"
      />
    </div>

    <template #footer-buttons>
      <ui5-button design="Emphasized" @click="handleSubmit">
        Update
      </ui5-button>
    </template>
  </BaseModal>
</template>

<script>
import TextInput from '../components/TextInput.vue';
import NumberInput from '../components/NumberInput.vue';
import SelectList from '../components/SelectList.vue';
import BooleanInput from '../components/BooleanInput.vue';
import DateInput from '../components/DateInput.vue';
import BaseModal from '../components/common/BaseModal.vue';

export default {
  name: 'ConfigModal',
  components: {
    BaseModal,
    TextInput,
    NumberInput,
    SelectList,
    BooleanInput,
    DateInput,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    configData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    inputValue: null,
  }),
  computed: {
    modalTitle() {
      return this.configData?.appKey;
    },
  },
  watch: {
    value(isOpen) {
      if (isOpen) {
        this.setInputValue();
      } else {
        this.inputValue = null;
      }
    },
  },
  mounted() {
    this.setInputValue();
  },
  methods: {
    setInputValue() {
      this.inputValue = this.formData[this.schema.name];
    },
    closeDialog() {
      this.$emit('input', false);
    },
    updateForm(fieldName, value) {
      this.$set(this.formData, fieldName, value);
      this.inputValue = value;
    },
    validateForm() {
      const input = this.$refs.inputField;

      return input.isValid;
    },
    handleSubmit() {
      if (!this.validateForm()) {
        return;
      }
      this.$emit('submit-form', this.formData);
      this.closeDialog();
    },
  },
};
</script>

<style scoped>
.jumbotron {
  width: 100%;
  background-color: #e9ecef;
}
</style>
