import Vue from 'vue';
import Vuex from 'vuex';
import { dkConstants } from '../dk-constants';
import homeStore from './home';

Vue.use(Vuex);

const getFromLocalStorage = (key) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const saveToLocalStorage = (key, value) => {
  const now = new Date();
  const ttl = 24 * 60 * 60 * 1000; // 1 day

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const store = new Vuex.Store({
  modules: {
    home: homeStore,
  },
  state: {
    csrfToken: null,
    jwt: getFromLocalStorage('storedJwt'),
    loggedIn: getFromLocalStorage('storedLoggedIn'),
    showGlobalDialog: false,
    errors: [],
    toastMsg: '',
    toastDuration: dkConstants.DEFAULT_TOAST_DURATION,
  },
  mutations: {
    SET_SHOW_GLOBAL_DIALOG(state, value) {
      state.showGlobalDialog = value;
    },
    ADD_ERROR(state, error) {
      state.errors.push(error);
    },
    RESET_ERRORS(state) {
      state.errors = [];
    },
    REMOVE_ERROR(state, idx) {
      state.errors.splice(idx, 1);
    },
    SET_JWT(state, jwt) {
      state.jwt = jwt;
      saveToLocalStorage('storedJwt', jwt);
    },
    SET_CSRF_TOKEN(state, csrfToken) {
      state.csrfToken = csrfToken;
    },
    SET_LOGGED_IN(state, loggedIn) {
      state.loggedIn = loggedIn;
      saveToLocalStorage('storedLoggedIn', loggedIn);
    },
    SET_TOAST_MSG(state, msg) {
      state.toastMsg = msg;
    },
    RESET_TOAST_MSG(state) {
      state.toastMsg = '';
    },
    SET_TOAST_DURATION(state, duration) {
      state.toastDuration = duration;
    },
  },
  actions: {
    showToastMessage({ commit }, message) {
      commit('SET_TOAST_MSG', message);
    },
    displayErrorDialog({ commit }, error) {
      commit('SET_SHOW_GLOBAL_DIALOG', true);
      commit('ADD_ERROR', error);
    },
    resetDialog({ commit }) {
      commit('RESET_ERRORS');
      commit('SET_SHOW_GLOBAL_DIALOG', false);
    },
    login({ commit }, jwt) {
      commit('SET_JWT', jwt);
      commit('SET_LOGGED_IN', true);
    },
    logout({ commit }) {
      commit('SET_JWT', null);
      commit('SET_LOGGED_IN', false);
    },
  },
  getters: {
    getLoggedIn: (state) => {
      return state.loggedIn;
    },
  },
});

export default store;
