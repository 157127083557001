<template>
  <section class="section">
    <div v-if="list" style="width: 100%">
      <ui5-busy-indicator :active="timeReportListBusy" size="Medium" style="width: 100%">
        <ui5-card class="medium">
          <ui5-card-header slot="header" title-text="Time Data Reports" />
          <ui5-list id="timeReportList" style="height: 480px" separators="None">
            <ui5-li v-for="(timeReport, i) in timeReports.monthlyReports" 
            :key="i" 
            icon="add" 
            @click="showDetail(i)">
              <div class="report-row">
                <div class="report-text">
                  Time Data Report for
                  <span v-if="timeReports.employeeName">
                    {{ timeReports.employeeName }}
                  </span>
                  <span v-if="timeReport.startDate != null">
                    from {{ timeReport.startDate }}
                  </span>
                  <span v-if="timeReport.endDate != null">
                    to {{ timeReport.endDate }}
                  </span>
                </div>
                <div class="report-button">
                  <ui5-button class="download-excel-report-button" @click.stop="generateExcelReport(
                    timeReports.employeeId,
                    timeReport.startDate,
                    timeReport.endDate)">
                    Download
                  </ui5-button>
                </div>
              </div>
            </ui5-li>
          </ui5-list>
        </ui5-card>
      </ui5-busy-indicator>
      <div class="text-right" style="width: 100%; padding: 15px 15px 4px 15px">
        <ui5-button class="export-button" @click="generateExcelReport(
          employeeId,
          fromDate,
          toDate)">
          Download Full Report
        </ui5-button>
      </div>
    </div>

    <div v-else style="width: 100%">
      <ui5-card class="medium">
        <ui5-card-header slot="header" :title-text="heading" interactive @click="showList">
          <ui5-icon slot="avatar" name="less" />
        </ui5-card-header>

        <TimeReportDetailed :time-report="selectedTimeReport" style="height: 650px; overflow-y: auto" />
      </ui5-card>
    </div>
  </section>
</template>

<script>
import TimeReportDetailed from './TimeReportDetailed.vue';
import api from '../api/index';
export default {
  name: "TimeReportList",
  components: {
    TimeReportDetailed
  },

  props: {
    timeReports: {
      type: Object,
      default: () => { },
    },
    fromDate: {
      type: [String, Date],
      default: '',
    },
    toDate: {
      type: [String, Date],
      default: '',
    },
    employeeId: {
      type: String,
      default: '',
    }
  },
  data: function () {
    return {
      selectedTimeReport: {},
      timeReportListBusy: false,
      list: true,
      heading: '',
    }
  },

  watch: {
    timeReports() {
      this.list = true;
    }
  },

  methods: {
    showDetail(index) {
      const timeData = this.timeReports.monthlyReports[index];
      this.list = false;
      this.selectedTimeReport = timeData;
      this.heading = 'Time Data Report for ' + this.timeReports.employeeName
      if (this.selectedTimeReport.startDate != null) {
        this.heading += ' from ' + this.selectedTimeReport.startDate;
      }
      if (this.selectedTimeReport.endDate != null) {
        this.heading += ' to ' + this.selectedTimeReport.endDate;
      }
    },

    showList() {
      this.list = true;
      this.selectedTimeReport = {};
    },

    generateExcelReport(employeeId, fromDate, toDate) {
      this.loading = true;
      const excelTimeReportFilter = {
        employeeId: employeeId,
        fromDate: fromDate,
        toDate: toDate
      }
      api
        .downloadTimeDataExcelReport(excelTimeReportFilter)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const link = document.createElement('a');
          link.href = encodeURI(URL.createObjectURL(blob));
          link.setAttribute('download', 'time_data_report.xlsx');
          link.setAttribute('style', 'display: none');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.report-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.report-text {
  flex-grow: 1;
  padding-right: 15px;
}

.report-button {
  flex-shrink: 0;
}
</style>